import axios from "axios";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { setConstantValue, WatchFileKind } from "typescript";
import { createCanvas } from "../utils/canvas";
import teams from "./teams.json";

const sorter = (function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );

export default function InputForm(props) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();
  function fetchResult() {
    axios.get('https://live.hapoel.dev').then( (response) => {
      const { data } = response;
      const { homeTeam, awayTeam, homeGoals, awayGoals,  } = (data.live || data.last);
      setValue('homeTeam', homeTeam);
      setValue('awayTeam', awayTeam);
      setValue('homeScore', homeGoals);
      setValue('awayScore', awayGoals);
      handleSubmit(onSubmit)();
    } )
  }

  useEffect(fetchResult,[]);

  const { onChange } = props;

  const onSubmit = (data) => {
    const { homeTeam, awayTeam, homeScore, awayScore, homeScorePen, awayScorePen } = data;
    const homeTeamLogo = teams.find((t) => t.name === homeTeam)?.url || "";
    const awayTeamLogo = teams.find((t) => t.name === awayTeam)?.url || "";
    createCanvas(homeTeamLogo, awayTeamLogo, homeScore, awayScore,homeScorePen, awayScorePen, onChange);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>Home Team</legend>
        <select {...register("homeTeam")}>
          {teams.sort(sorter).map((t) => (
            <option key={t.name}>{t.name}</option>
          ))}
        </select>
        <input
          type="number"
          min="0"
          max="9"
          defaultValue="0"
          {...register("homeScore")}
        />
        <input
          type="number"
          min="0"
          max="9"
          defaultValue="0"
          {...register("homeScorePen")}
        />
      </fieldset>
      <fieldset>
        <legend>Away Team</legend>
        <select {...register("awayTeam")}>
          {teams.sort(sorter).map((t) => (
            <option key={t.name}>{t.name}</option>
          ))}
        </select>
        <input
          type="number"
          min="0"
          max="9"
          defaultValue="0"
          {...register("awayScore")}
        />
        <input
          type="number"
          min="0"
          max="9"
          defaultValue="0"
          {...register("awayScorePen")}
        />
      </fieldset>
      <input type="submit" />
      <button onClick={fetchResult}>אוטומטי</button>
    </form>
  );
}

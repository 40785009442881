import { visitFunctionBody } from "typescript";

const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => reject(console.log(`load ${url} fail`));
    img.src = url;
  });
};

const draw = (ctx, src, x, y) => {
  return loadImage(src).then((img) => {    
      ctx.drawImage(img, x, y);
  });
};

const drawLogo = (ctx, src, x, y) => {
  return loadImage(src).then((img) => {       
    const {width, height} = img;      
      const max_height = 237;
      const max_width = 231;
      let p = 1;
      if(height > max_height){
        p = max_height / height;
      }
      if((width * p) > max_width) {
        p = max_width/ width;
      }
      console.log(width,height, p, width * p, height * p);      
      const marginX = ((width * p) - max_width) / 2;
      const marginY = ((height * p) - max_height) / 2;
      console.log(marginX,marginY);
      const sx = 0;
      const sy = 0;
      const sWidth = width
      const sHeight = height;
      const dx = x - marginX;
      const dy = y - marginY;
      const dWidth = width * p;
      const dHeight = height * p; 
      ctx.drawImage(img,sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
  });
};



const createCanvas = (homeLogo, awayLogo, homeScore, awayScore, homeScorePen, awayScorePen,onChange) => {
  const canvas = document.querySelector("canvas");
  const layer1 = canvas.getContext("2d");
  layer1.clearRect(0, 0, canvas.width, canvas.height);
  layer1.font = "italic 102px Rubik";
  layer1.fillStyle = "#fff";
  document.fonts
    .load("italic 102px Rubik")
    .then(() => draw(layer1, "./assets/bg.png", 0, 0))
    .then(() =>
      Promise.all([
        draw(layer1, "./assets/ft-bg.png", 203, 292),
        draw(layer1, "./assets/right.png", 899, 474),
        draw(layer1, "./assets/left.png", 0, 569),
        draw(layer1, "./assets/vs.png", 471, 583)
      ])
    )
    .then(() =>
      Promise.all([
        draw(layer1, "./assets/ft.png", 118, 311),
        //Logo Home
        drawLogo(layer1, `./assets/logos/${awayLogo}`, 205, 513),
        //Logo Away
        drawLogo(layer1, `./assets/logos/${homeLogo}`, 644, 513),
      ])
    )
    .then(() => {
      finalScore(homeScore, awayScore,homeScorePen, awayScorePen);
    })
    .then(() => {
      const dataUrl = canvas.toDataURL('image/png');
      onChange(dataUrl);
    });
};

function finalScore(home, away, homePen, awayPen) {
  const layer1 = document.querySelector("canvas").getContext("2d");
  layer1.fillText(away, 44, 685);
  //Away Score
  layer1.fillText(home, 950, 590);

  if(homePen + awayPen > 0){
  //Penelties  
  const canvas = document.querySelector("canvas");
  const layer2 = canvas.getContext("2d");  
  layer2.clearRect(0, 0, 0, 0);
  layer2.font = "italic 36px Rubik";
  layer2.fillStyle = "#fff";
  layer2.fillText(`(${awayPen})`, 54, 720);
  //Away Score
  layer2.fillText(`(${homePen})`, 960, 625);
  }

}

function download() {
  const canvas = document.querySelector("canvas");
  const dataUrl = canvas.toDataURL('image/png');
  const oldImg = document.querySelector('img');
  if(oldImg){
    oldImg.remove();
  }
  const img = document.createElement('img');
  img.src = dataUrl;
  document.body.appendChild(img);  
}

export { createCanvas };

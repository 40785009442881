import InputForm from "./components/InputForm";
import "./styles.css";
import { useState } from "react";

export default function App() {
  const [dataImage, setDataImage] = useState();
  return (
    <div className="App">
      <h1>HPT Tempalte Generator</h1>
      <InputForm onChange={setDataImage} />
      <img src={dataImage} />
      <canvas width="1080" height="1080" />
    </div>
  );
}
